import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import {
  Section,
  SectionHeading,
  SectionLede,
} from "../styledComponents/section"
import ContactForm from "../components/contactForm"
import { ContactSection } from "../styledComponents/contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Contact = ({ data }) => {
  const lede = data.wpPage.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '')
  return (
    <>
    <Layout displayNavBorder>
      <SEO title={data.wpPage.title} />
      <Section>
        <Heading level={1}>
          {data.wpPage.title}
        </Heading>
        <SectionLede>
          {parse(lede)}
        </SectionLede>
        <ContactSection>
          <ContactForm />
        </ContactSection>
      </Section>
    </Layout>
    </>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "contact" }) {
      title
      content
    }
  }
`
